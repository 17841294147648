.contentContainer {
    width: calc(100% - 60px);
    max-width: 1200px;
    margin: 20px auto 50px;
    font-size: 18px;
}

.contentContainer h1 {
    text-align: center;
}

.contentContainer h4 {
    margin: 60px 0 25px;
}

.contentContainer .content {
    font-size: 24px;
}

.contentContainer .content a {
    color: #9CF;
    text-decoration: none;
}

.contentContainer .content a:hover {
    text-decoration: underline;
}

ol.content, ol.content ol {
    margin-left: 0px;
    padding-left: 40px;
}

@media only screen and (max-width: 900px) {
	.contentContainer {
	  width: calc(100% - 20px);
      margin: 10px auto 20px;
      font-size: 12px;
	}

    .contentContainer h4 {
        margin: 30px 0 15px;
    }

    .contentContainer .content {
        font-size: 18px;
    }

    ol.content, ol.content ol {
        margin-left: 0px;
        padding-left: 30px;
    }
}

@media only screen and (max-width: 600px) {
	.contentContainer {
	  width: calc(100%);
      margin: 10px auto 15px;
      font-size: 10px;
	}

    .contentContainer h4 {
        margin: 30px 0 15px;
    }

    .contentContainer .content {
        font-size: 14px;
    }

    ol.content, ol.content ol {
        margin-left: 0px;
        padding-left: 15px;
    }
}