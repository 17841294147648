.container__slider {
  position: relative;
  width: 100%;
  width: 900px;
  aspect-ratio: 16 / 9;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #36C2;
}

.container__slider > button {
  background-color: #21325E;
  border-radius: 50%;
  outline: none;
  border: 0;
  padding: 4px 4px 4px 6px;
  color: #69C;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
}

@media(min--moz-device-pixel-ratio:0) {
  .container__slider > button {
    padding: 2px 4px 6px 6px;
  }
}

.container__slider > button:hover {
  color: #9CF;
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 5px;
}

.slider__btn-prev {
  position: absolute;
  left: 5px;
}

.container__slider__links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.container__slider__links-small {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  outline: none;
  border: 0;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 1s ease;
  margin: 0 2px;
}

.container__slider__links-small-active {
  background-color: black;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: contain;
  height: auto;
  transform: translateX(0);
  transition: transform 1s ease;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5 {
  transform: translateX(-400%);
}

.slider__item-active-6 {
  transform: translateX(-500%);
}

.slider__item-active-7 {
  transform: translateX(-600%);
}

.slider__item-active-8 {
  transform: translateX(-700%);
}

.slider__item-active-9 {
  transform: translateX(-800%);
}

.slider__item-active-10 {
  transform: translateX(-900%);
}

.slider__item-active-11 {
  transform: translateX(-1000%);
}

.slider__item-active-12 {
  transform: translateX(-1100%);
}

.slider__item img {
  width: 900px;
  aspect-ratio: 16 / 9;
  object-fit: scale-down;
}
