.footer {
  width: 100%;
  height: 200px;
  background: #21325e;
  background: #235;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  transition: 0.8s;
  /* margin-top: 200px; */
}

.socialMedia a {
  margin: 15px;
  width: 60px;
  height: 60px;
  display: inline-block;
  transition: .6s;
}

.socialMedia svg {
  color: white;
  margin: 0;
  font-size: 70px;
  cursor: pointer;
  transition: .6s;
  fill: currentColor;
}

.socialMedia svg:hover {
  animation-name: social-icon-hover;
	animation-duration: 1s;
  transition: transform .6s, color 0.6s;
}

.socialMedia .linkedin svg {
  transform: scale(.8);
}

.socialMedia .github svg {
  transform: scale(.78);
}

.socialMedia .facebook svg {
  transform: scale(.8);
}

.socialMedia .tiktok svg {
  transform: scale(1.08);
  margin-left: 5px;
  margin-bottom: 9px;
}

.socialMedia .instagram svg {
  transform: scale(.8);
  padding: 5px;
  margin: 3px -6px -1px -2px;
}

.socialMedia .x svg {
  transform: scale(.70);
  margin-left: -16px;
}

.socialMedia .flickr svg {
  transform: scale(.9);
  margin-bottom: 8px;
  margin-left: -6px;
}

.socialMedia .linkedin svg:hover {
  color: #0A66C2;
}

.socialMedia .github svg:hover {
  color: #FFF;
}

.socialMedia .facebook svg:hover {
  color: #0866FF;
}

.socialMedia .twitch svg:hover {
  color: #A970FF;
}

.socialMedia .tiktok svg:hover {
  color: #000;
}

.socialMedia .youtube svg:hover {
  color: #FF0033;
}

.socialMedia .instagram svg:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  border-radius: 50%;
}

.socialMedia .flickr svg:hover {
  color: #E1E7EA;
}

.socialMedia .x svg:hover {
  color: #000;
}

svg.flickr .blue {
  color: #223355;
}

svg.flickr .purple {
  color: #223355;
}

svg.flickr:hover .blue {
  color: #1565C0;
}

svg.flickr:hover .purple {
  color: #FF4081;
}

@keyframes social-icon-hover
{
  0%   { color: white;  }
  5%   { color: red; }
  10%  { color: green;  }
  15%  { color: blue; }
  20%  { color: red; }
  25%  { color: green;  }
  30%  { color: blue; }
  35%  { color: orange  }
  40%  { color: red; }
  60%  { color: orange; }
  /* 70%  { color: red; } */
  /* 100% { color: #0A66C2;  } */
}

.footer .privacyterms {
  font-size: .8em;
  color: #FFFFFF99;
}

.footer .privacyterms a {
  color: #FFFFFF66;
  text-decoration: none;
}

.footer .privacyterms a:hover {
  color: #FFFFFF99;
}

.footer p {
  color: white;
}

@media only screen and (max-width: 1100px) {
  .socialMedia a {
    margin: 10px;
    width: 50px;
    height: 50px;
    transition: .6s;
  }

  .footer {
    height: 150px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    height: 130px;
    transition: 0.8s;
  }

  .socialMedia a {
    margin: 5px;
    width: 40px;
    height: 40px;
    display: inline-block;
    transition: .6s;
  }

  .socialMedia svg {
    font-size: 50px;
    transition: .6s;
  }

  .socialMedia .twitch svg, .socialMedia .flickr svg {
    transform: scale(.75);

  }

  .socialMedia .twitch svg {
    margin-bottom: -10px;
    margin-left: -4px;

  }

  .socialMedia .tiktok svg {
    transform: scale(.75);
    margin-bottom: 0px;
    margin-left: 0;
  }

  .socialMedia .instagram svg {
    transform: scale(1);
    padding: 5px;
    margin: 0 -6px -2px 1px;
  }

  .socialMedia .flickr svg {
    margin-left: -5px;
    margin-bottom: -5px;
  }

  .socialMedia .x svg {
    margin-left: -3px;
    margin-bottom: -2px;
  }
}

@media only screen and (max-width: 500px) {
  .footer {
    height: 110px;
    transition: 0.8s;
  }

  .socialMedia a {
    margin: 5px;
    width: 35px;
    height: 35px;
    display: inline-block;
    transition: .6s;
  }

  .socialMedia svg {
    font-size: 40px;
    transition: .6s;
  }

  .socialMedia .twitch svg, .socialMedia .flickr svg {
    transform: scale(.6);

  }

  .socialMedia .twitch svg {
    margin-bottom: -13px;
    margin-left: -8px;

  }

  .socialMedia .youtube svg {
    margin-left: -4px;
  }

  .socialMedia .tiktok svg {
    transform: scale(.6);
    margin-bottom: -4px;
    margin-left: -6px;
  }

  .socialMedia .instagram svg {
    transform: scale(1);
    padding: 5px;
    margin: 0 -6px -1px -6px;
  }

  .socialMedia .flickr svg {
    margin-left: -20px;
    margin-bottom: -10px;
  }

  .socialMedia .x svg {
    margin-left: -16px;
    margin-bottom: -2px;
  }
}

@media only screen and (max-width: 410px) {
  .socialMedia a {
    margin: 5px;
    width: 30px;
    height: 30px;
    display: inline-block;
    transition: .6s;
  }

  .socialMedia svg {
    font-size: 35px;
    transition: .6s;
  }

  .socialMedia .twitch svg, .socialMedia .flickr svg {
    transform: scale(.5);

  }

  .socialMedia .twitch svg {
    margin-bottom: -16px;
    margin-left: -11px;

  }

  .socialMedia .tiktok svg {
    transform: scale(.5);
    margin-bottom: -8px;
    margin-left: -7px;
  }

  .socialMedia .instagram svg {
    transform: scale(1);
    padding: 5px;
    margin: 0 -6px -1px -2px;
  }

  .socialMedia .flickr svg {
    margin-left: -16px;
    margin-bottom: -11px;
  }

  .socialMedia .x svg {
    margin-left: -8px;
    margin-bottom: -1px;
  }

}