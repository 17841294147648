
.gameContainerST {
    margin: -20px -20px;
    padding: 2px 0;
    min-height: 650px;
    /* height: calc(100vh - 50px); */
    /* background-color: #236; */
    color: #69C;
}

.gameContainerST .highlight {
    color: #9CF;
}

.gameContainerST .gameTitle, .gameContainerST .scoreBoard, .gameContainerST .startButton  {
    background-color: #123;
    box-shadow: 0 0 5px 5px #36C;
    transition: box-shadow 0.6s;
}

.gameContainerST .gameTitle h1 {
    text-align: center;
    margin: 0;
    padding: 0;
}

.gameContainerST .gameTitle {
    min-width: 380px;
    /* max-width: 80vw; */
    border: #012 1px solid;
    margin: 20px auto;
    padding: 5px 15px;
    border-radius: 8px;
    position: relative;
    transition: padding 0.6s, margin 0.6s;
}

.gameContainerST .scoreBoard {
    min-width: 380px;
    /* max-width: 80vw; */
    border: #012 1px solid;
    margin: 20px auto;
    padding: 8px 15px;
    border-radius: 8px;
    /* margin-top: 100px; */
    position: relative;
    transition: padding 0.6s, margin 0.6s;
}
.gameContainerST .scoreBoard .displayGridSize {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.gameContainerST .startButton {
    position: relative;
    min-width: 380px;
    /* max-width: 80vw; */
    border: #012 1px solid;
    margin: 20px auto;
    padding: 8px 15px;
    border-radius: 8px;
}

.gameContainerST .startButton .startButtonGrid {
    display: flex;
    justify-content: center;
}

.gameContainerST button.displayGridSizeButton {
    color: #9CF;
    border: #69F 1px solid;
    padding: 3px 10px;
    margin: 0 5px;
    text-transform: none;
    min-width: auto;
    line-height: 1rem;
    transition: 0.6s;
}

.gameContainerST .startButton button {
    border: #69F 1px solid;
    padding: 2px 10px;
    margin: 0 10px;
}

.gameContainerST .startButton button.startAnimation {
    animation: border-glow 1s linear infinite;
}
@keyframes border-glow {
    0% { box-shadow: 0px 0px 0px 0px #535FED; }
    15% { box-shadow: 0px 0px 5px 5px #535FED; }
    30% { box-shadow: 0px 0px 0px 0px#535FED; }
    45% { box-shadow: 0px 0px 5px 5px #535FED; color: #9CF; }
    60% { box-shadow: 0px 0px 0px 0px#535FED; }
    70% { box-shadow: 0px 0px 5px 5px #535FED; }
    90% { box-shadow: 0px 0px 0px 0px#535FED; }
}

.gameContainerST .startButton .gameShareButtons {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.gameContainerST .startButton .gameShareButtons button {
    padding: 0;
    margin: 0;
    min-width: 0;
    border: none;
}


.gameContainerST .gameBoardContainer {
    display: grid;
    position: relative;
    min-width: 400px;
    min-height: 400px;
    max-width: 90vw;
    max-height: calc(100vh - 450px);
    border: #012 1px solid;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
    grid-gap: 2px;
    aspect-ratio: 1;
    transition: max-height 0.8s, max-width 0.8s;
    background-color: #123;
    box-shadow: 0 0 5px 5px #36C;
    transition: box-shadow 0.6s;
}

.gameContainerST .gameBoardContainer .wrapperContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 0px);
    height: calc(100% - 1px);
    border: #012 9px solid;
    border-radius: 8px;
    background: transparent;
    z-index: 5;
    pointer-events: none;
}

.gameContainerST .tileButton {
    font-size: min(calc(20vw / var(--grid-size)), calc(20vh / var(--grid-size)));
    border: #69F 1px solid;
    border-radius: 8px;
    border-color: #9CF #36C #36C #9CF ;
    background-color: #69F;
    cursor: pointer;
    aspect-ratio: 1;
    transition: font-size 0.3s, size 0.3s;
    position: relative;
    z-index: 5;
}

.gameContainerST .tileButton::before {
    content: '';
    position: absolute;
    top: 25%;
    left: calc(100% + 1px);
    display: inline-block;
    height: 50%;
    width: 7px;
    background-color: #6699FF99;
    border-radius: 0 8px 8px 0;
    z-index: 1;
}

.gameContainerST .tileButton::after {
    content: '';
    position: absolute;
    left: 25%;
    top: calc(100% + 1px);
    display: inline-block;
    width: 50%;
    height: 7px;
    background-color: #6699FF99;
    border-radius: 0 0 8px 8px;
    z-index: 1;
}

.gameContainerST .tileButton .wrapperItem {
    height: 100%;
    align-content: center;
}

.flashContainer, .winnerContainer, .bigFlashContainer {
    text-align: center;
    white-space: nowrap;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    padding: 10px 30px;
    border-radius: 10px;
    z-index: 6;
    pointer-events: none;
}

.bigFlashContainer {
    color: #36C;
    font-weight: bold;
    background: -webkit-radial-gradient(#3C6, #36C, #36C);
    -webkit-text-stroke: 2px #36F;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: game-big-flash-animation 1s linear infinite;
}

.flashContainer {
    color: #C00;
    background-color: #369;
    font-size: min(3vw, 3vh);
    animation: game-flash-animation .6s linear infinite;
}

.winnerContainer {
    font-size: min(5vw, 5vh);
    font-weight: bold;

    z-index: 7;
}

.winnerContainer .winnerText {
    background: -webkit-radial-gradient(#0F0, #F00, #00F);
    background-clip: text;
    -webkit-text-stroke: 1px #236;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: winner-animation 1s linear infinite;
}


@keyframes winner-animation {
    0% {
        font-size: min(5vw, 5vh);
        background: -webkit-radial-gradient(#0F0, #F00, #00F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    25% {
        font-size: min(7vw, 7vh);
        background: -webkit-radial-gradient(#00F, #F00, #0F0);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    50% {
        font-size: min(5vw, 5vh);
        background: -webkit-radial-gradient(#F00, #0F0, #00F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    75% {
        font-size: min(7vw, 7vh);
        background: -webkit-radial-gradient(#00F, #0F0, #F00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
    100% {
        font-size: min(5vw, 5vh);
        background: -webkit-radial-gradient(#0F0, #F00, #00F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
}

@keyframes game-flash-animation {
    0% { color: #0C0; font-size: min(3vw, 3vh); }
    50% { color: #CC0; font-size: min(3.2vw, 3.2vh); }
    100% { color: #0C0; font-size: min(3vw, 3vh); }
}

@keyframes game-big-flash-animation {
    0% { font-size: min(0vw, 0vh); }
    50% { font-size: min(50vw, 50vh); }
    100% { font-size: min(0vw, 0vh); }
}

@media only screen and (max-width: 700px) {
    .gameBoardContainer {
        box-shadow: 0 0 4px 2px #36C;
        transition: box-shadow 0.6s;
    }

    .gameContainerST .gameTitle, .gameContainerST .scoreBoard, .gameContainerST .startButton  {
        box-shadow: 0 0 4px 2px #36C;
        transition: box-shadow 0.6s;
    }

    .gameContainerST .gameTitle {
        margin: 10px auto;
        padding: 0px 10px;
        transition: padding 0.6s, margin 0.6s;
    }

    .gameContainerST .scoreBoard {
        margin: 10px auto;
        padding: 5px 10px;
        transition: padding 0.6s, margin 0.6s;
    }

    .gameContainerST .startButton {
        margin: 10px auto;
        padding: 8px 10px;
        transition: padding 0.6s, margin 0.6s;
    }
}

@media only screen and (max-width: 500px) {
    .gameContainerST .scoreBoard {
        font-size: .8rem;
        transition: padding 0.6s, margin 0.6s, font 0.6s;
    }

    button.displayGridSizeButton {
        font-size: .6rem;
        line-height: .7rem;
        transition: 0.6s;
    }

    .gameContainerST .startButton button {
        font-size: .6rem;
        transition: 0.6s;
    }
}