.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  background-color: #21325e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  padding: 0;
  margin: 0;
}

.nounderline a
{
	text-decoration: none;
}

.button
{
	color: #69F;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	padding: 8px 15px;
	margin: 5px 10px;
	border: #535FED 1px solid;
	border-radius: 6px;
	text-decoration: none;
	background: #00000033;
}

.button .symbol {
  vertical-align: text-top;
  line-height: 14px;
}

.button .symbol.next {
  margin-left: 8px;
}

.button .symbol.prev {
  margin-right: 8px;
}

.button:hover
{
	background: #00000011;
	border-color: #99F;
}

.mainContainer {
  min-height: max(calc(100vh - 310px), 400px);
  background-color: #21325e;
  padding: 20px;
}

.errorMsg {
	color: #C00;
	background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23C00"  viewBox="0 0 50 50" width="30px" height="30px"%3E%3Cpath d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/%3E%3C/svg%3E') no-repeat scroll 6px 50%;
	background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="35px" height="35px"%3E%3Cpath fill="%23C00" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/%3E%3Cpath fill="%23FFF" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"/%3E%3C/svg%3E') no-repeat scroll 6px 50%;
  /* background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 200'%3E%3Cpath d='M10 10h123v123H10z'/%3E%3C/svg%3E" ); */
	border: #622 1px solid;
	margin: 5px 0px 10px;
	padding: 12px;
	padding-left: 45px;

	border-radius: 5px;
	overflow: hidden;
	font-weight: bold;
	text-align: left;
}



::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  /* border: 5px solid red; */

}

::-webkit-scrollbar-thumb {
  background-color: #888;
  background-clip: padding-box;
  border: 2px solid #000;
  /* border: none; */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #000;
  /* padding: 2px; */
}
/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  background-color: #000;
  display: block;
  border-style: solid;
  height: 8px;
  width: 8px;
  cursor: pointer;
  /* border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black; */
}


::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 10px;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #888 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}


::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 10px;
  border-width: 7px 7px 0 7px;
  border-color: #888 transparent transparent transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  border-color: #777777 transparent transparent transparent;
}



::-webkit-scrollbar-button:single-button:horizontal:decrement {
  width: 8px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #888 transparent  transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #777777 transparent  transparent;
}


::-webkit-scrollbar-button:single-button:horizontal:increment {
  width: 8px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #888;
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  border-color: transparent transparent transparent #777777;
}