
.footerSticky {
    position: sticky;
    background-color: black;
    bottom: 0;
    /* height: 26px; */
    padding: 6px 20px;
    text-align: right;
    z-index: 10;
}

@media only screen and (min-width: 1000px) {
    .footerSticky {
      display: none;
    }
}