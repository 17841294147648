.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projects .projectTitle {
  text-align: center;
  font-weight: bolder;
  margin: 10px 0px;
}

.projectList {
  width: 85vw;
  height: auto;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, 23%);
  place-items: center;
  transition: 0.8s;
  justify-content: center;
  /* grid-gap: 10px; */
}

.projectItem {
  border-radius: 15px;
  width: 350px;
  height: 300px;
  margin: 35px 0;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  background-color: #00000033;
  text-align: center;
  transition: 0.8s;
}
.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 18px;
  /* height: 40px; */
  padding: 10px 0px 2px;
  transition: 0.8s;
}


@media only screen and (max-width: 1920px) {
  .projectList {
    width: 90vw;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 1800px) {
  .projectList {
    width: 96vw;
    transition: 0.8s;
  }

  .projectItem h1 {
    font-size: 17px;
    transition: 0.8s;
  }

  .projectItem {
    width: 330px;
  }
}

@media only screen and (max-width: 1650px) {
  .projectList {
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, 30%);
    width: 85vw;
    transition: 0.8s;
  }
  .projectItem {
    width: 350px;
    margin: 30px 0;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 1400px) {
  .projectList {
    width: 96vw;
    transition: 0.8s;
  }

  .projectItem h1 {
    font-size: 17px;
    transition: 0.8s;
  }

  .projectItem {
    width: 330px;
  }
}

@media only screen and (max-width: 1200px) {
  .projectList {
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, 48%);
    width: 80vw;
    transition: 0.8s;
  }

  .projectItem {
    width: 350px;
    margin: 25px 0;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 950px) {
  .projectList {
    width: 100%;
  }

  .projectItem {
    width: 330;
    margin: 20px 0;
    transition: 0.8s;
  }

}

@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
  }

  .projectList {
    width: 100%;
  }

  .projectItem {
    width: 350px;
    margin: 20px 0;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 450px) {
  .projectItem h1 {
    font-size: 16px;
    transition: 0.8s;
  }

  .projectItem {
    width: 320px;
    margin: 20px 0;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 400px) {
  .projectItem {
    width: 300px;
    margin: 20px 0;
    transition: 0.8s;
  }
}