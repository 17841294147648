.experience {
    margin: -20px -15px;
    min-width: 380px;
}


.experience .detailContainer {
    margin: 10px auto;
    width: max(400px, 50%);
}

.experience h1 {
    /* margin-top: 50px; */
    color: #3e497a;
    color: #9CF;
    font-size: min(30px, max(1.4vw, 13px));
    /* width: 900px; */
    text-align: center;
    transition: 0.6s;
}

.experience h1 span {
    margin-top: 2px;
    color: #3e497a;
    width: 40px;
    height: 30px;
    font-size: min(25px, max(1.4vw, 14px));
    ;
    transition: margin 0.2s linear;
}

.experience h1 span.prev {
    float: left;
}

.experience h1 span.next {
    float: right;
}

.experience h1 span:hover {
    margin-top: 1px;
    transition: margin 0.2s linear;
}

.experience h1 span a {
    text-decoration: none;
    transition: font-size 0.2s linear;
}

.experience h1 span a:hover {
    font-size: 30px;
    transition: font-size 0.2s linear;
}

.prevNextContainer {
    padding: 5px 10px;
}

.prevNextContainer h1,
.prevNextContainer h2,
.prevNextContainer h3,
.prevNextContainer h4,
.prevNextContainer h5 {
    margin: 5px;
}

.vertical-timeline {
    max-width: 1400px;
    padding-top: 40px;
}

.vertical-timeline-element-title {
    color: #9CF;
}

.vertical-timeline-element-content {
    color: #FFFFFFCC;
    background-color: #00000033;
    box-shadow: 0 3px 0 #FFFFFF11;
}

.vertical-timeline-element-content-arrow {
    border-right-color: #00000033;
}

.vertical-timeline-element-content h4 a,
.vertical-timeline-element-content h4 a:visited {
    color: #69F;
}

h4.institution a,
h4.institution a:visited,
.vertical-timeline-element-content a,
.vertical-timeline-element-content a:visited {
    color: #9CF;
    text-decoration: none;
}

h4.institution a:hover,
.vertical-timeline-element-content a:hover {
    text-decoration: underline;
}

.experience div.content {
    margin-top: 5px;
    margin-left: 23px;
    margin-right: 20px;
    transition: 0.8s;
}

.highlight {
    color: #9FC;
}

.experience ol,
.experience ul {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.experience ol.content li,
.experience ul.content li {
    margin-bottom: 5px;
    transition: 0.8s;
}

.experience ol li,
.experience ul li {
    margin-bottom: 5px;
    transition: 0.8s;
}

.experience .showhidecontent {
    display: none;
}

.experience h4 {
    transition: 0.8s;
}

.experience h4.marker {
    margin: 12px 0 10px;
    padding-left: 22px;
    line-height: 1.5rem;
    transition: 0.8s;
}

.experience h4.marker::before {
    color: #69C;
    content: '\2713';
    float: left;
    margin-left: -22px;
}

.experience h4 .displayMoreButton {
    float: right;
    margin: 0;
    padding: 2px 10px;
    transition: 0.8s;
}

@media only screen and (max-width: 1650px) {
    .experience h4 .displayMoreButton {
        margin-right: -10px;
        transition: 0.8s;
    }
}


@media only screen and (max-width: 1550px) {

    .experience .content,
    .experience ol,
    .experience ul {
        font-size: 14px;
    }

    .experience h4.marker {
        font-size: 14px;
        margin: 8px 0 5px;
        transition: 0.8s;
    }

    .experience h4 .displayMoreButton {
        font-size: 12px;
        margin-right: -15px;
        transition: 0.8s;
    }

    .bouncingDotAnimation {
        transform: scale(.8);
        margin-left: 5px;
    }
}

@media only screen and (max-width: 1420px) {
    .experience h4.marker {
        font-size: 13px;
        margin: 3px 0 2px;
        transition: 0.8s;
    }

    .experience h4 .displayMoreButton {
        font-size: 11px;
        margin-right: -18px;
        transition: 0.8s;
    }

    .bouncingDotAnimation {
        transform: scale(.6);
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1280px) {
    .experience h4 .displayMoreButton {
        float: none;
        min-width: 0;
        padding: 0;
        margin: 0;
    }

    .experience h4 .displayMoreButton .buttonText {
        display: none;
    }
}

@media only screen and (max-width: 1170px) {
    .experience h4.marker {
        font-size: 16px;
        margin: 12px 0 10px;
        transition: 0.8s;
    }

    .experience h4 .displayMoreButton {
        float: right;
        margin: 0;
        padding: 2px 10px;
        min-width: 60px;
        font-size: 14px;
        transition: 0.8s;
    }

    .experience h4 .displayMoreButton .buttonText {
        display: inline;
        padding: 0;
        margin: 0;
    }

    .bouncingDotAnimation {
        transform: scale(1);
        margin-left: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .experience h4 .displayMoreButton {
        margin-right: -10px;
        transition: 0.8s;
    }
}


@media only screen and (max-width: 780px) {
    .experience h4.marker {
        font-size: 14px;
        margin: 8px 0 5px;
        transition: 0.8s;
    }

    .experience h4 {
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 10px;
        transition: 0.8s;
    }

    .experience h4 .displayMoreButton {
        font-size: 12px;
        margin-right: -15px;
        transition: 0.8s;
    }

    .bouncingDotAnimation {
        transform: scale(.8);
        margin-left: 5px;
    }
}

@media only screen and (max-width: 680px) {
    .experience h4 .displayMoreButton {
        float: none;
        min-width: 0;
        padding: 0;
        margin: 0;
        margin-right: -20px;
    }

    .experience h4 .displayMoreButton .buttonText {
        display: none;
    }

}

.experience h4 .showMoreButtonRight {
    float: right;
    margin-top: -10px;
    background: transparent;
    border: none;
    display: inline;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 1.75;
    padding: 6px 8px;
    min-width: 64px;
    outline: 0;
    color: #1976d2;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
}

.experience h4 .showMoreButtonRight .bouncingDotAnimation {
    margin-top: 10px;
    display: inline-flex;
}

.vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 #00000014, 0 3px 0 4px #0000000d;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    -webkit-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s;
    visibility: visible;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s;
    visibility: visible;
}


@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns::before {
        left: 50%;
        margin-left: -2px;
    }
}

.vertical-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #3e497a;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,
    .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse .6s;
        animation: cd-bounce-2-inverse .6s;
    }
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}


@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}