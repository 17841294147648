.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  width: 100%;
  margin-top: -20px;
  margin-bottom: -20px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
  margin-top: 0;
  margin-bottom: 50px;
  transition: 0.8s;
}

.about h3 {
  font-size: 20px;
  margin-bottom: 40px;
  transition: 0.8s;
}

.about .prompt {
  max-width: 900px;
  width: calc(100% - 0px);
  font-size: 30px;
  transition: 0.8s;
  margin: 0;
}

h1.animated-text-title-header {
  margin-bottom: 0px;
}

.about .skillsIconsContainer {
  max-width: 900px;
  margin: 20px 0 40px;
  transition: 0.8s;
}

.skillIconContainerFieldset {
  width: auto;
  margin: 0;
  padding: 10px 0 15px;
  border-color: #122142;
  border-width: 2px;
  border-radius: 10px;
  box-shadow: 0px -3px 2px 0px #747DE8;
  animation: radial-glow 1.5s linear infinite;
  transition: 0.8s;
}

@keyframes radial-glow {
  0% { box-shadow: 3px 0px 2px 0px #535FED; }
  25% { box-shadow: 0px 3px 2px 0px #535FED; }
  50% { box-shadow: -3px 0px 2px 0px #535FED; }
  75% { box-shadow: 0px -3px 2px 0px #535FED; }
  100% { box-shadow: 3px 0px 2px 0px #535FED; }
}

.skillIconContainerFieldset:hover {
  animation-play-state: paused;
  border-color: #091020 !important;
  transition: 0.8s;
}

.skillIconContainerFieldset legend {
  color: #6db3f8;
  margin-left: 10px;
  background-color: #21325e;
  border-color: transparent;
  text-align: left;
  animation: color-glow 5s linear infinite;
}

@keyframes color-glow {
  0% { color: #6db3f8; }
  50% { color: #535FED; }
  100% { color: #6db3f8; }
}

.skillsIcons div .skill-rotating-icon:not(:hover)  {
  animation: size-change 1.6s linear infinite;
}

.skillsIcons div:nth-child(8n-7) .skill-rotating-icon:not(:hover) {
  animation-delay: 0s;
}
.skillsIcons div:nth-child(8n-6) .skill-rotating-icon:not(:hover) {
  animation-delay: 0.2s;
}
.skillsIcons div:nth-child(8n-5) .skill-rotating-icon:not(:hover) {
  animation-delay: 0.4s;
}
.skillsIcons div:nth-child(8n-4) .skill-rotating-icon:not(:hover) {
  animation-delay: 0.6s;
}
.skillsIcons div:nth-child(8n-3) .skill-rotating-icon:not(:hover) {
  animation-delay: 0.8s;
}
.skillsIcons div:nth-child(8n-2) .skill-rotating-icon:not(:hover) {
  animation-delay: 1s;
}
.skillsIcons div:nth-child(8n-1) .skill-rotating-icon:not(:hover) {
  animation-delay: 1.2s;
}
.skillsIcons div:nth-child(8n) .skill-rotating-icon:not(:hover) {
  animation-delay: 1.4s;
}

@keyframes size-change {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills {
  font-size: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: 0.8s;
}

.skills h1 {
  color: #3e497a;
  margin: 2.5em 0 0.8em;
  margin-bottom: -0.5em;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.skills .list h2 {
  margin: 1.5em 0 .6em;
}

.list span {
  font-size: 22px;
}

.skillsIcons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  /* margin: 1em 0 2em; */
}

.skills .skillsIcons {
  margin-bottom: -0.6em;
}

.about-me-summary {
  min-height: 110px;
  padding: 20px;
  border: #122142 1px solid;
  border-radius: 10px;
  animation: border-random-glow 8s linear infinite, height 0.8s, width 0.8s;
  transition: 0.8s;
  /* box-shadow: 0px 3px 0px 0px #535FED; */
}

@keyframes border-random-glow {
  0% { box-shadow: 0px 0px 0px 0px #535FED; }
  5% { box-shadow: 3px 0px 0px 0px #535FED; }
  6% { box-shadow: 0px 0px 0px 0px #535FED; }
  9% { box-shadow: -3px 0px 0px 0px #535FED; }
  10% { box-shadow: 0px 0px 0px 0px #535FED; }
  15% { box-shadow: 0px 3px 0px 0px #535FED; }
  16% { box-shadow: 0px 0px 0px 0px #535FED; }
  19% { box-shadow: 0px -3px 0px 0px #535FED; }
  20% { box-shadow: 0px 0px 0px 0px #535FED; }
  25% { box-shadow: 3px 0px 0px 1px #535FED; }
  26% { box-shadow: 0px 0px 0px 0px #535FED; }
  29% { box-shadow: 0px 3px 0px 1px #535FED; }
  30% { box-shadow: 0px 0px 0px 0px #535FED; }
  35% { box-shadow: 0px -3px 0px 0px #535FED; }
  36% { box-shadow: 0px 0px 0px 0px #535FED; }
  39% { box-shadow: 3px 0px 0px 0px #535FED; }
  40% { box-shadow: 0px 0px 0px 0px #535FED; }
  45% { box-shadow: 3px 3px 0px 0px #535FED; }
  46% { box-shadow: 0px 0px 0px 0px #535FED; }
  50% { box-shadow: 3px 3px 3px 0px #535FED; }
  51% { box-shadow: 0px 0px 0px 0px #535FED; }
  55% { box-shadow: -3px 3px 3px 0px #535FED; }
  56% { box-shadow: 0px 0px 0px 0px #535FED; }
  60% { box-shadow: 0px 0px 3px 0px #535FED; }
  61% { box-shadow: 0px 0px 0px 0px #535FED; }
  65% { box-shadow: 0px -3px 3px 0px #535FED; }
  66% { box-shadow: 0px 0px 0px 0px #535FED; }
  70% { box-shadow: 0px 0px 8px 8px #535FED; }
  75% { box-shadow: 0px 0px 0px 0px#535FED; }
  80% { box-shadow: 0px 0px 8px 8px #535FED; }
  85% { box-shadow: 0px 0px 0px 0px#535FED; }
  90% { box-shadow: 0px 0px 8px 8px #535FED; }
  100% { box-shadow: 0px 0px 0px 0px#535FED; }
}

.skill-rotating-icon {
  transition: transform 1.2s;
}

.skillsIcons div .skill-rotating-icon:hover {
  animation-name: skill-icon-hover;
	animation-duration: 1s;
	transition: transform .6s;
}

@keyframes skill-icon-hover
{
  0%   { transform: rotate(0); }
  10%  { transform: rotate(480deg); }
  20%  { transform: rotate(960deg); }
  30%  { transform: rotate(1440deg); }
  40%  { transform: rotate(1920deg); }
  50%  { transform: rotate(2400deg); }
  60%  { transform: rotate(1920deg); }
  70%  { transform: rotate(1440deg); }
  80%  { transform: rotate(960deg); }
  90%  { transform: rotate(480deg); }
  100% { transform: rotate(360deg); }
}


.skill-rotating-icon {
  transition: 0.6s;
}

.home .gameContainer {
  margin-top: 40px;
  height: calc(100vh - 50px);
  background-color: #236;
}

.home .gameContainer .gameTitle {
  margin-top: 100px;
}


@media only screen and (max-width: 1200px) {
  .about h2 {
    font-size: 70px;
    margin-bottom: 40px;
    transition: 0.8s;
  }

  .about h3 {
    font-size: 18px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 900px) {
  .about h2 {
    font-size: 60px;
    margin-bottom: 25px;
    transition: 0.8s;
  }

  .about h3 {
    font-size: 18px;
    margin-bottom: 30px;
    transition: 0.8s;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 25px;
    width: calc(100% - 20px);
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 90px;
    transition: 0.8s;
  }

  .about .skillsIconsContainer {
    width: calc(100% - 20px);
    transition: 0.8s;
  }
}

@media only screen and (max-width: 720px) {
  .about h2 {
    font-size: 50px;
    margin-bottom: 15px;
    transition: 0.8s;
  }

  .about h3 {
    font-size: 16px;
    margin-bottom: 20px;
    transition: 0.8s;
  }

  .about .prompt {
    font-size: 23px;
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 80px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 660px) {
  .about .prompt {
    font-size: 20px;
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 70px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
    margin-bottom: 2px;
    transition: 0.8s;
  }

  .about h3 {
    font-size: 14px;
    margin-bottom: 20px;
    transition: 0.8s;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 18px;
    /* width: 80%; */
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 65px;
    transition: 0.8s;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }

  .skills .skillsIcons {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .about h2 {
    font-size: 35px;
    margin-bottom: 0px;
    transition: 0.8s;
  }

  .about h3 {
    font-size: 12px;
    margin-bottom: 15px;
    transition: 0.8s;
  }

  .about .prompt {
    font-size: 15px;
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 55px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 450px) {
  .about .prompt {
    font-size: 12px;
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 45px;
    transition: 0.8s;
  }
}

@media only screen and (max-width: 400px) {
  .about h2 {
    font-size: 30px;
    margin-bottom: 0px;
    transition: 0.8s;
  }

  .about .prompt {
    font-size: 12px;
    transition: 0.8s;
  }

  .about-me-summary {
    min-height: 45px;
    transition: 0.8s;
  }
}