.navbar {
  width: 100%;
  height: 100px;
  background: #21325e;
  background: #215e32;
  background: #235;
  position: sticky;
  top: -1px;
  z-index: 20;
  transition: height 0.5s;
}

.navbar.shrink
{
	height: 70px;
	transition: height 0.5s;
}

.navbar.shrink a
{
	font-size: 22px;
	transition: font-size 0.5s;
}

.navbar .thehooman {
  left: 10px;
  top: 10px;
  position: absolute;
}

.navbar .thehooman img
{
	height: 80px;
	transition: height 0.5s, left 0.5s, top 0.5s, opacity 0.5s, transform .6s;
}

.navbar.shrink .thehooman {
  left: 5px;
  top: 5px;
}

.navbar.shrink .thehooman img
{
	height: 60px;
	transition: height 0.5s, left 0.5s, top 0.5s, opacity 0.5s, transform .6s;
}

.navbar .thehooman:hover img {
  animation-name: hooman-icon-hover;
	animation-duration: 1s;
  transition: opacity 0.5s, transform .6s;
}

.navbar .theotherhooman img {
  max-width: 60px;
  border-radius: 50%;
  transition: 0.6s;
}

.navbar.shrink .theotherhooman img {
  max-width: 40px;
  transition: 0.6s;
}


@keyframes hooman-icon-hover
{
  0%   { opacity: 1;  }
  5%   { opacity: .5; }
  10%  { opacity: 1;  }
  15%  { opacity: .6; }
  20%  { opacity: 1;  }
  55%  { opacity: .5; }
  100% { opacity: 1;  }
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar .links a:not(.thehooman):not(.loggedin) {
  color: white;
  text-decoration: none;
  padding: 5px 20px;
  margin: 20px;
  font-size: 25px;
  border: #1c2b47 1px solid;
  border-radius: 10px;
  transition: background 0.8s, margin 0.8s, padding 0.8s, font-size 0.8s, box-shadow 0.5s;
}

.navbar.shrink .links a:not(.thehooman):not(.loggedin) {
  padding: 0px 20px;
  transition: background 0.8s, margin 0.8s, padding 0.8s, font-size 0.8s, box-shadow 0.5s;
}

.navbar .links a:not(.thehooman):hover {
  /* background-color: #24314d; */
  border-color: #346;
  box-shadow: 0px 0px 3px 2px #346;
  transition: background 0.8s, margin 0.8s, padding 0.8s, font-size 0.8s, box-shadow 0.5s;
}

.navbar .links a.active {
  border-color: #69F !important;
  background-color: #69F3;
  animation: box-shadow-glow 3s linear infinite;
  transition: box-shadow-glow 0.8s;
}

.navbar .links a.active:hover {
  border-color: #9CF;
}

@keyframes box-shadow-glow {
  0% { box-shadow: none; }
  50% { box-shadow: 0px 0px 5px 2px #69F; }
  100% { box-shadow: none; }
}

.toggleButton {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 60%;
  transform: translateX(-50%);
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: 0.8s
}

.toggleButton svg {
  font-size: 50px;
  transition: 0.8s;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar.shrink .toggleButton svg {
  /* margin-top: -25px; */
  font-size: 40px;
  transition: 0.8s;
}

.navbar .menuContainer {
  width: 100%;
  height: 100px;
  transition: 0.4s;
}

.navbar.shrink .menuContainer {
  width: 100%;
  height: 70px;
  transition: 0.4s;
}

#open .menuContainer {
  margin-top: 0px;
  padding: 50px 0;
  height: 100vh;
  width: 60vw;
  background: #1d2c49;
  transition: 0.4s;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin: 50px auto;
  /* width: calc(100% - 20px); */
}


#open a:not(.thehooman) {
  width: 100%;
  text-align: center;
  margin: 5px 0px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  background-color: #EEEEFF08;
}

@media only screen and (max-width: 1000px) {
  .loginLogoutMenu {
    display: none;
  }
  .navbar .theotherhooman {
    display: none;
  }

  .navbar .links a:not(.thehooman):not(.loggedin) {
    padding: 3px 15px;
    margin: 10px;
    font-size: 2vw;
    transition: background 0.8s, margin 0.8s, padding 0.8s, font-size 0.8s, box-shadow 0.5s;
  }

  .navbar.shrink .links a:not(.thehooman):not(.loggedin) {
    padding: 2px 15px;
    transition: background 0.8s, margin 0.8s, padding 0.8s, font-size 0.8s, box-shadow 0.5s;
  }
}

@media only screen and (min-width: 1000px) {
  .loginLogoutMenu {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar .theotherhooman {
    right: 15px;
    position: absolute;
    border-radius: 50%;
    transition: height 0.5s, left 0.5s, top 0.5s, opacity 0.5s, transform .6s;
  }

  .navbar.shrink .theotherhooman {
    transition: height 0.5s, left 0.5s, top 0.5s, opacity 0.5s, transform .6s;
  }
}

@media only screen and (max-width: 800px) {
  .navbar .links {
    width: calc(100% - 10px);
    justify-content: right;
    transition: width 0.8s;
  }

  .navbar, .navbar.shrink, .navbar .menuContainer, .navbar.shrink .menuContainer  {
	  height: 70px;
  }

  .navbar .thehooman, .navbar.shrink .thehooman {
    left: 5px;
    top: 5px;
    transition: 0.5s;
  }

  .navbar .thehooman img, .navbar.shrink .thehooman img
  {
    height: 60px;
    transition: height 0.5s, left 0.5s, top 0.5s, opacity 0.5s, transform .6s;
  }


}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }

  #close a:not(.thehooman) {
    display: none;
  }

  .navbar .thehooman, .navbar.shrink .thehooman {
    left: 20px;
    top: 15px;
    transition: 0.5s;
  }

  .navbar .thehooman img, .navbar.shrink .thehooman img {
    height: 45px;
    transition: 0.5s;
  }

  .navbar .toggleButton svg, .navbar.shrink .toggleButton svg {
    /* margin-top: -25px; */
    font-size: 40px;
    transition: 0.8s;
  }

  .navbar a {
    border: none;
  }
  .navbar .links a:not(.thehooman) {
    font-size: 25px;
    padding: 6px 0px;
    margin: 5px 5px 6px;
    border-radius: 4px;
  }

}


@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}




/*


@media only screen and (max-width: 980px) {
  .navbar .links a:not(.thehooman) {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 7px 15px;
    transition: margin 0.8s, padding 0.8s, font-size 0.8s;
  }
}

@media only screen and (max-width: 800px) {
  .navbar .links {
    width: calc(100% - 20px);
    justify-content: right;
    transition: width 0.8s;
  }
}

@media only screen and (max-width: 700px) {
  .navbar .links {
    width: calc(100% - 20px);
    justify-content: right;
    transition: width 0.8s;
  }

  .navbar .links a:not(.thehooman) {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a:not(.thehooman) {
    display: none;
  }

  .navbar a {
    border: none;
  }
  .navbar .links a:not(.thehooman) {
    font-size: 25px;
    padding: 6px 0px;
    margin: 5px 5px 6px;
    border-radius: 4px;
  }

  .navbar .thehooman {
    left: 30px;
    top: 15px;
    transition: 0.5s;
  }

  .navbar.shrink .thehooman {
    left: 30px;
    top: 10px;
    transition: 0.5s;
  }

  .navbar .thehooman img {
    height: 55px;
    opacity: 0.8;
    transition: 0.5s;
  }

  .navbar.shrink .thehooman img {
    height: 45px;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
*/