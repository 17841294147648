.games {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gameLink {
    color: #FFF;
    text-decoration: none;
}

.gameLink:hover {
    color: #9CF;
}


.gameList {
    width: 85vw;
    height: auto;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, 23%);
    place-items: center;
    transition: 0.8s;
    justify-content: center;
    /* grid-gap: 10px; */
}

.gameItem {
    border-radius: 15px;
    width: 350px;
    height: 580px;
    margin: 35px 0;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    background-color: #00000033;
    text-align: center;
    transition: 0.8s;
    cursor: pointer;
}

.gameItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.gameItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.gameItem h1 {
    font-size: 18px;
    /* height: 40px; */
    padding: 10px 0px 2px;
    transition: 0.8s;
}


@media only screen and (max-width: 1920px) {
    .gameList {
        width: 90vw;
        transition: 0.8s;
    }
}

@media only screen and (max-width: 1800px) {
    .gameList {
        width: 96vw;
        transition: 0.8s;
    }

    .gameItem h1 {
        font-size: 17px;
        transition: 0.8s;
    }

    .gameItem {
        width: 330px;
    }
}

@media only screen and (max-width: 1650px) {
    .gameList {
        /* grid-template-columns: 1fr 1fr 1fr; */
        grid-template-columns: repeat(auto-fit, 30%);
        width: 85vw;
        transition: 0.8s;
    }

    .gameItem {
        width: 350px;
        margin: 30px 0;
        transition: 0.8s;
    }
}

@media only screen and (max-width: 1400px) {
    .gameList {
        width: 96vw;
        transition: 0.8s;
    }

    .gameItem h1 {
        font-size: 17px;
        transition: 0.8s;
    }

    .gameItem {
        width: 330px;
    }
}

@media only screen and (max-width: 1200px) {
    .gameList {
        /* grid-template-columns: 1fr 1fr; */
        grid-template-columns: repeat(auto-fit, 48%);
        width: 80vw;
        transition: 0.8s;
    }

    .gameItem {
        width: 350px;
        margin: 25px 0;
        transition: 0.8s;
    }
}

@media only screen and (max-width: 950px) {
    .gameList {
        width: 100%;
    }

    .gameItem {
        width: 330;
        margin: 20px 0;
        transition: 0.8s;
    }

}

@media only screen and (max-width: 800px) {
    .gameList {
        grid-template-columns: 1fr;
    }

    .gameList {
        width: 100%;
    }

    .gameItem {
        width: 350px;
        margin: 20px 0;
        transition: 0.8s;
    }
}

@media only screen and (max-width: 450px) {
    .gameItem h1 {
        font-size: 16px;
        transition: 0.8s;
    }

    .gameItem {
        width: 320px;
        margin: 20px 0;
        transition: 0.8s;
    }
}

@media only screen and (max-width: 400px) {
    .gameItem {
        width: 300px;
        margin: 20px 0;
        transition: 0.8s;
    }
}