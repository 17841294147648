.project {
  width: 100%;
  /*height: calc(100vh - 100px);*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project h1 {
  /* margin-top: 50px; */
  color: #3e497a;
  color: #9CF;
  font-size: 35px;
  width: 900px;
  text-align: center;
  transition: 0.6s;
}

.project h1 span {
  margin-top: 3px;
  color: #3e497a;
  width: 40px;
  height: 30px;
  font-size: 25px;
  transition: margin 0.2s linear;
}

.project h1 span.prev {
	float: left;
}

.project h1 span.next {
	float: right;
}

.project h1 span:hover {
	margin-top: 1px;
	transition: margin 0.2s linear;
}

.project h1 span a {
	text-decoration: none;
	transition: font-size 0.2s linear;
}

.project h1 span a:hover {
	font-size: 30px;
	transition: font-size 0.2s linear;
}

.project h1 #react-tooltip-data-html {
	border-radius: 10px;
}

.project h1 #react-tooltip-data-html .tooltipContent {
	margin: 5px 10px;
	color: #FFF;
	font-size: 25px;
	text-align: center;
}

.project h1 #react-tooltip-data-html .tooltipContent img {
	display: block;
	margin: 15px auto;
	max-width: 400px;
	max-height: 300px;
	width: auto;
	height: auto;
	border-radius: 5px;
}

.project img {
  width: 900px;
  border-radius: 10px;
}

.project p {
  font-size: 40px;
  color: #3e497a;
}

.slide-container
{
	width: 900px;
	transition: 0.6s;
}

.slide-container .slide-image
{
	overflow: hidden;
	aspect-ratio: 16 / 9;
}

.descriptionContainerFieldset {
	font-size: 20px;
	max-width: 900px;
	padding: 10px 20px 20px;
	margin: 20px 20px 5px;
	border-color: #122142;
	border-width: 2px;
	border-radius: 10px;
	box-shadow: 0px -3px 2px 0px #747DE8;
	animation: radial-glow 1.5s linear infinite;
  }

  .descriptionContainerFieldset:hover {
	animation-play-state: paused;
	border-color: #091020 !important;
  }

  .descriptionContainerFieldset legend {
	color: #6db3f8;
	font-weight: bold;
	font-size: 24px;
	margin-left: 10px;
	background-color: #21325e;
	border-color: transparent;
	animation: color-glow 5s linear infinite;
  }


.description
{
	margin-top: 10px;
}

.technology
{
	margin-top: 30px;
}

.versions
{
	margin-top: -1em;
	margin-left: 80px;
}

.external-links
{
	text-align: center;
	margin: 25px 0 0px;
	padding: 5px 0;
}

.buttonContainer
{
	margin: 25px 0 10px;
}

/* .buttonContainer a
{
	margin: 0 10px;
} */

.external-links svg
{
	vertical-align: middle;
}



@media only screen and (max-width: 950px) {
	.project h1 span {
		font-size: 2.5vw;
		transition: 0.6s;
	}

	.project h1, .project h1 span a {
		font-size: 3.5vw;
		transition: 0.6s;
	}

	.project h1, .project img, .slide-container {
	  width: calc(100% - 20px);
	  transition: 0.6s;
	}

	.descriptionContainerFieldset {
		width: calc(100% - 20px);
		padding: 5px 10px 10px;
		font-size: 1rem;
		transition: 0.6s;
	}

	.descriptionContainerFieldset legend {
		font-size: 3.5vw;
		line-height: 1;
	}
}