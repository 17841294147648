.game {
	width: 100%;
	/*height: calc(100vh - 100px);*/
	/* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.game h1 {
	margin-top: 50px;
	color: #3e497a;
	color: #9CF;
	font-size: 35px;
	/* width: 900px; */
	text-align: center;
}

.game h1 span {
	margin-top: 3px;
	color: #3e497a;
	width: 40px;
	height: 30px;
	font-size: 25px;
	transition: margin 0.2s linear;
}

.game h1 span.prev {
	float: left;
}

.game h1 span.next {
	float: right;
}

.game h1 span:hover {
	margin-top: 1px;
	transition: margin 0.2s linear;
}

.game h1 span a {
	text-decoration: none;
	transition: font-size 0.2s linear;
}

.game h1 span a:hover {
	font-size: 30px;
	transition: font-size 0.2s linear;
}

.tooltipContent {
	border-radius: 10px;
}

.tooltipContent h2 {
	margin: 5px 8px;
	color: #FFF;
	font-size: 25px;
	text-align: center;
}

.tooltipContent img {
	display: block;
	margin: 15px auto;
	max-width: 400px;
	max-height: 300px;
	width: auto;
	height: auto;
	border-radius: 5px;
}

.game img {
	width: 900px;
	border-radius: 10px;
}

.game p {
	font-size: 40px;
	color: #3e497a;
}

.buttonContainer {
	width: 380px;
	text-align: center;
	padding: 10px 20px;
	margin: 25px auto 10px;
	transition: 0.6s;
}

.buttonContainer .button {
	margin: 0 40px;
}

.buttonContainer span.prev, .buttonContainer span.next {
	display: inline-block;
	width: 60px;
	font-size: 1.5rem;
	vertical-align: middle;
}

.buttonContainer span.prev a, .buttonContainer span.next a {
	text-decoration: none;
}

@media only screen and (max-width: 900px) {
	.buttonContainer {
		margin: 20px auto 0;
		transition: 0.6s;
	}
}

@media only screen and (max-width: 450px) {
	.buttonContainer {
		margin: 0px auto 0;
		transition: 0.6s;
	}
}


@media only screen and (max-width: 420px) {
	.buttonContainer {
		margin: -40px auto 0;
		transition: 0.6s;
	}
}