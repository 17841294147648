.navbar .theLoggedOutUser {
    font-size: 45px !important;
    transition: 0.6s;
}

.navbar.shrink .theLoggedOutUser {
    font-size: 35px !important;
    transition: 0.6s;
}

img.theLoggedInUser {
    max-width: 50px;
    border-radius: 50%;
    transition: 0.6s;
}

.navbar.shrink img.theLoggedInUser {
    max-width: 40px;
    border-radius: 50%;
    transition: 0.6s;
}

img.theLoggedInUser.footerMenu {
    max-width: 30px;
    border-radius: 50%;
    transition: 0.6s;
}

.theLoggedOutUser.footerMenu {
    font-size: 25px !important;
    transition: 0.6s;
}